* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}

/* About me container */
/* #about_me {
  padding-top: 0;
  height: 40rem;
}

@media only screen and (min-width: 600px) {
  #about_me {
    padding-top: 0;
    height: 26rem;
  }
} */

/* Tic Tac Toe Iframe */
@media only screen and (min-width: 600px) {
  .tic_tac_toe_class {
    height: 400px;
  }
}

/* Navbar */
.remove-navbar {
  display: none !important;
}

/* @components/InfoSection/index.js */
#btn_wrap_mobile {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

@media only screen and (max-width: 768px) {
  #btn_wrap_desktop {
    display: none;
  }

  #btn_wrap_mobile {
    display: flex;
  }
}

@media only screen and (min-width: 768px) {
  #btn_wrap_desktop {
    display: flex;
  }

  #btn_wrap_mobile {
    display: none;
  }
}

/* @components/InfoSection/InfoElements.js */
#reload_btn:active {
  color: red;
}

#reload_btn > p {
  font-size: 0.25em;
  margin-left: 0.5rem;
}
